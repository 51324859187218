import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import type {FormEvent} from "react";
import {
  ArrowButton,
  ChatContainer,
  MainContainer,
  MessageInput,
  MessageList,
  SendButton
} from "@chatscope/chat-ui-kit-react";
import {
  ChatMessage,
  MessageContent,
  MessageContentType,
  MessageDirection,
  MessageStatus,
  useChat
} from "@chatscope/use-chat";
import {AgentConversationHeader} from "./AgentConversationHeader";
import {ChatMessageList} from "./ChatMessageList/ChatMessageList";
import "./chat.scss";
import classNames from "classnames";
import {useDetectVirtualKeyboard} from "./useDetectVirtualKeyboard";
import {Conversations} from "./Conversations";

export const Chat = () => {

    const messageInputRef = useRef<HTMLDivElement | null>(null);
    const {currentMessage, setCurrentMessage, sendMessage, activeConversation, currentUser,
       currentMessages, sendTyping } = useChat();
    
    const editorRef = useRef<HTMLDivElement | null>(null);
    
    useEffect(() => {
      // Dodajemy atrybuty do messageinput
      const msgInput = document.querySelector(".cs-message-input__content-editor") as HTMLDivElement;
      editorRef.current = msgInput;
      msgInput?.setAttribute("tabIndex", "0");
      msgInput?.setAttribute("role", "textbox");
      msgInput?.setAttribute("spellcheck", "false");
      // nie możemy tutaj użyć aria-placeholder, bo NVDA czyta go dwa razy
      // albo aria-label, albo aria-labelledby
      msgInput?.setAttribute("aria-label", "Wpisz wiadomość");
      msgInput?.setAttribute("aria-multiline", "true");
      
      const sendButton = document.querySelector(".cs-message-input__tools .cs-button.cs-button--send");
      if (sendButton ) {
        sendButton.setAttribute("aria-label", "Wyślij wiadomość");
      }
      
      return () => {
        document.querySelector(".cs-message-input__content-editor-wrapper")?.removeAttribute("tabIndex");
        if (sendButton ) {
          sendButton.removeAttribute("aria-label");
        }
      }
    },[]);
    
    // Ustawiamy focus na messageinput
    useEffect(() => {
      console.log("Ustawiam focus");
      if (messageInputRef.current) {
        messageInputRef.current.focus();
      }
    },[activeConversation]);
    
    const onPaste = useCallback((e: React.ClipboardEvent<HTMLDivElement>) => {

      // Blokuję domyślną akcję przeglądarki
      e.preventDefault();
      
      const selection = window.getSelection();
      
      if ( selection ) {
        
        const range = selection.getRangeAt(0);
        
        // Czyszczę zawartość z htmla pobierając tylko tekst z wklejonych danych
        const text = e.clipboardData.getData("text/plain");
        
        // Możliwe, że jest coś aktualnie zaznaczone
        // dlatego musimy to skasować
        range.deleteContents();
        // Na miejsce kursora wstawiamy wklejoną zawartość, ale przeczyszczoną z htmla
        range.insertNode(document.createTextNode(text));
        
        // Kursor na koniec wstawionego tekstu
        selection.collapseToEnd();
        
        // To nie może być target, bo zależy od tego gdzie wsrtawiam....
        const editor = document.querySelector(".cs-message-input__content-editor") as HTMLDivElement;
        
        if ( editor ) {
          // Wartość do edytora została wstawiona programowo bez zmiany state
          // State nie chcę zmieniać, bo powoduje to problemy z ustawieniem kursora w odpowiednim miejscu
          // Ponadto, jeżeli wcześniej nie było nic w edytorze, to przycisk wysłania się nie zaświeci
          // Dlatego tutaj dispatchuję ręcznie event input, który spowoduje, że całość się zaktualizuje
          // i uruchomi się onChange na MessageInput (który de facto jest uruchamiany przez handler onInput)
          editor.dispatchEvent(new Event("input", {bubbles: true}));
        }
        
      }
    },[]);
    
    // http://perfectionkills.com/the-poor-misunderstood-innerText/
    const onSend = useCallback((message:string, textContent: string, innerText?: string) => {
      
        // Ponieważ na mobile jest wyłączone wysyłanie za pomocą enter,
        // to muszę pobrać content wiadomości bezpośrenio, bo brakuje mi tutaj wartości innerText
        // (use-chat, śledzi tylko jedna wartość)
        // Jeżeli innerText jest undefined to znaczym, że wysyłka jest z przycisku, a nie za pomocą enter
        const msgContent = innerText ?? editorRef.current?.innerText;
        
        if ( activeConversation && currentUser ) {
            const newMessage = new ChatMessage({
                id: "",
                status: MessageStatus.Sent,
                content: msgContent as unknown as MessageContent<MessageContentType.TextHtml>,
                senderId: currentUser?.id,
                direction: MessageDirection.Outgoing,
                contentType: MessageContentType.TextHtml
            });
            
            sendMessage({
                generateId: true,
                conversationId: activeConversation.id,
                message: newMessage,
                senderId: currentUser?.id,
            });

            messageInputRef.current?.focus();
        }
    },[activeConversation, currentUser, sendMessage, editorRef]);

    const onSubmit = useCallback((evt:FormEvent) => {
      evt.preventDefault();
      onSend(currentMessage, currentMessage);
    },[onSend, currentMessage]);
    
    const noMessagesRef = useRef<HTMLButtonElement>(null);
    
    // Aktualnie to jest handler dla przycisku w nagłówku i dla przycisku następnego pow "wyślij"
    // ale możliwe, że trzeba będzie to rozbić na dwa osobne handlery, 
    // jeżeli okaże się, że przycisk w nagłówku powinien kierować do np do ostatnio czytanej wiadomości
    const onGoToConversationClick = useCallback(() => {
      // Jeżeli nie ma żadnych wiadomości, to przechodzimy do informacji o braku wiadomości
      if ( currentMessages.length === 0 ) {
        window.setTimeout(() => {
          noMessagesRef.current?.focus();
        },20);
      } else { // Jeżeli są wiadomości, to przechodzimy do pierwszej z nich
        const firstMessage = document.querySelector("[data-cs-message-list] .cs-message__custom-content > div:first-child") as HTMLDivElement;
        // Obejście na błąd w Talkback polegający na tym, że gubi focus jeżeli ustawia się go na na tym samym elemencie,
        // na którym był wcześniej przed kliknięciem przycisku
        // Dobrane eksperymantalnie, że zmiana focusa po 20ms pomaga
        // Błąd jest zgłoszony tutaj: https://issuetracker.google.com/issues/231606408
        window.setTimeout(() => {
          firstMessage?.focus();
        },20);
      }
  
    },[noMessagesRef, currentMessages.length]);

  // Element informacyjny definuję sobnie tutaj, żeby było łatwiej obsługiwać focusy
  const noMessages = useMemo(() => 
      <MessageList.Content className="h-100 d-flex align-items-center justify-content-center no-messages">
          <button className="btn visually-hidden-focusable no-messages__btn" ref={noMessagesRef} onClick={() => messageInputRef.current?.focus()}>
            Brak wiadomości.<br />
            Naciśnij, aby przejść do okna wprowadzania wiadomości i rozpocząć rozmowę
          </button>
      </MessageList.Content>,[messageInputRef]);
  
    const [sendVisible, setSendVisible] = useState(true);
  
    const onMessageChange = useCallback((message:string, textContent:string, innerText:string, nodes:NodeList) => {
      
      sendTyping( {
        content: "",
        throttle: true,
        conversationId: activeConversation!.id,
        isTyping: true,
        userId: currentUser!.id
      });
      
      setCurrentMessage(message);
      
    },[setCurrentMessage, sendTyping, activeConversation, currentUser]);
    
    const {isVirtualKeyboard, onKeyDown}  = useDetectVirtualKeyboard();
    
    const firstRender = useRef(true);
    
    // Przez błąd w use-chat, nie można tutaj użyć useMemo z zależnością od currentMessages (nie zmienia się tylko wiadoomści są dodawane do tablicy
    const lastMessage = (() => {
      // Nie chcemy ogłaszać alertu po piwerwszym wejściu na stronę, np po przeładowaniu jak są już wiadomości
      if ( firstRender.current === true) {
        firstRender.current = false;
        return "";
      }
      if( currentMessages.length > 0 ) {
        const lastGroup = currentMessages[currentMessages.length - 1];
        const mL = lastGroup.messages.length; 
        if (  mL > 0 ) {
          const lastMessage = lastGroup.messages[mL - 1]; 
          return  `${lastMessage.direction === MessageDirection.Incoming ? (lastMessage.senderId === "SYSTEM" ? "Informacja: " : "Klient: ") : "Wysłano: "} ${lastMessage.content as unknown as string}`;
        }
      } 
      
      return "";
      
    })();
    
    useEffect(() => {
      // Czyszczę zawartość last message, po timeoucie, bo nie chcę, żeby użytkownik mógł wejść na ten tekst
      // co jest możliwe w odpowiednim trybie czytania mimo tego, że nie ma focusu
      setTimeout(() => {
        const alert = document.getElementById("last-message-alert");
        if ( alert ) {
          alert.innerHTML = "";
        }
      }, 1000);
    },[lastMessage]);
    
    return (
        <MainContainer>
          <div id="last-message-alert" role="alert" className="visually-hidden" aria-live="assertive">
            {lastMessage}      
          </div>
          <Conversations />
              <ChatContainer aria-labelledby="remote-user-name" role="main">
                  <AgentConversationHeader as="ConversationHeader"
                                           onWriteClick={() => messageInputRef.current?.focus()} />
                  <ChatMessageList
                    /* @ts-ignore */  
                    as={MessageList}
                    noMessages={noMessages}
                  />
                <form /* @ts-ignore */  
                    as={MessageInput}
                    className="d-flex message-input align-items-center"
                    onSubmit={onSubmit}
                >
                  <MessageInput
                    className="message-input__input flex-grow-1"
                    placeholder="Wpisz wiadomość" 
                                sendButton={false}
                                attachButton={false}
                                fancyScroll={false} 
                                value={currentMessage} 
                                onKeyDown={onKeyDown}
                                onChange={onMessageChange}
                                autoFocus={true}
                                onPaste={onPaste}
                                onSend={onSend}
                                disabled={!activeConversation || activeConversation.data.state === 100}
                                sendOnReturnDisabled={isVirtualKeyboard}
                                ref={messageInputRef}
                  />
                  {/* Przycisk wysyłki jest schowany jeżeli focus przeskoczy na przycisk przejścia do początku rozmowy.
                      Czyli przyciski zamieniają się jeżeli przechodzi się pomiędzu nimi tabem - zapewnia do dobrą obsługe dla screen readerów
                      oraz spójność interfejsu w przypadku nawigowania za pomocą klawiatury
                      (interfejs nie skacze, a przycisk przejścia do początku rozmowy jest domyślnie niewidoczny) */}
                  <SendButton
                    className={classNames("mx-1 message-input__send-btn",{"visually-hidden-focusable": !sendVisible})}  
                    type="submit" 
                    disabled={currentMessage.length === 0}
                    aria-label="Wyślij wiadomość"
                  />
                  <ArrowButton direction="up"
                               type="button"
                               aria-label="Przejdź do pierwszej wiadomości"
                               className="mx-1 visually-hidden-focusable message-input__go-to-start-btn"
                               onFocus={() => setSendVisible(false)}
                               onBlur={() => setSendVisible(true)}
                               onClick={onGoToConversationClick}
                  />
                </form>
              </ChatContainer>
        </MainContainer>
    );
    
}