import {ConversationList, Sidebar} from "@chatscope/chat-ui-kit-react";
import {Conversation, useChat} from "@chatscope/use-chat";
import {ConversationItem} from "./ChatMessageList/ConversationItem";
import {useCallback} from "react";

export const Conversations = () => {
  
  const {conversations, setActiveConversation, activeConversation} = useChat();
  
  const onKeyPress = useCallback((e:React.KeyboardEvent<HTMLDivElement>, conversation: Conversation) => {
    if (e.key === "Enter" || e.key === " ") {
      if (activeConversation?.id !== conversation.id) {
        setActiveConversation(conversation.id);
      }
    }
  },[activeConversation, setActiveConversation]);

  const onClick = useCallback((e:React.MouseEvent<HTMLDivElement>, conversation: Conversation) => {
      if (activeConversation?.id !== conversation.id) {
        setActiveConversation(conversation.id);
      }
  },[activeConversation, setActiveConversation]);
  
  const onKeyDown = useCallback((evt:React.KeyboardEvent<HTMLDivElement>) => {
    const key = evt.key;
    const currentConversation = evt.currentTarget;
    
    if ( key === "ArrowUp" ) {
      const prev = currentConversation.parentElement?.previousElementSibling?.firstChild as HTMLElement;
      if( prev ) {
        prev.focus();
      }
    } else if ( key === "ArrowDown" ) {
      const next = currentConversation.parentElement?.nextElementSibling?.firstChild as HTMLElement;
      if ( next ) {
        next.focus();
      }
    }
    
  },[]);
  
  return (
    <Sidebar position="left" scrollable={false}>
      <ConversationList id="conversation-list">
        {conversations.map( conversation => <ConversationItem 
          key={conversation.id} 
          conversation={conversation}
          tabIndex={0}
          onKeyPress={(e) => onKeyPress(e, conversation)}
          onClick={(e) => onClick(e, conversation)}
          onKeyDown={onKeyDown}
        />)}
      </ConversationList>
    </Sidebar>
  )
}