import {Theme} from "di-chat-model";

const lightGray = "lightgray";
const darkGray = "gray";
const white = "white";
const black = "black";
const yellow = "yellow";
const blue = "blue";
const defaultPrimary = "#c6e3fa";
const defaultPrimaryLight = "#f6fbff";
const defaultPrimaryDark = "#6eaad8";
const defaultColorText = "rgba(0, 0, 0, .87)";
//const defaultColorTextMuted = "rgba(0, 0, 0, .6)"
const defaultColorTextDisabled = "rgba(0, 0, 0, .38)";
const defaultMessageInputPlaceholderColor = "rgba(0, 0, 0, 0.38)";
const defaultBorderColor = "#d1dbe3";
const defaultActiveColor = "#d5e7f5";
const defaultActivatedColor = "#2b6a9b";
const defaultActivatedBgColor = "#d5e7f5";
const defaultHoverColor = "#f3f8fc";

export const themes:Record<Theme, Record<string, string>> = {
  "default": {
    "--bs-body-bg": white,
    "--di-bs-card-bg": white,
    "--cs-color-text": defaultColorText,
    "--cs-color-bg-hover": "#f3f8fc", //
    "--cs-color-primary": defaultPrimary,
    "--cs-conversation-header-color": defaultColorText,
    "--cs-conversation-header-bg-color": defaultPrimaryLight,
    "--cs-message-group-message-content-incoming-color": defaultColorText,
    "--cs-message-group-message-content-incoming-bg-color": defaultPrimary,
    "--cs-message-group-message-content-outgoing-bg-color": defaultPrimaryDark,
    "--cs-message-group-message-content-outgoing-color": defaultColorText,
    "--cs-button-send-color": defaultPrimaryDark,
    "--cs-button-arrow-color": defaultPrimaryDark,
    "--cs-conversation-active-bg-color": defaultActiveColor,
    "--cs-conversation-activated-bg-color": defaultActivatedBgColor,
    "--cs-conversation-name-activated-color": defaultActivatedColor,
    "--di-message-content-border": "0",
    "--di-message-content-incoming-border": "0",
    "--cs-message-list-bg-color": white,
    "--cs-chat-container-message-input-border-top": `1px solid ${defaultBorderColor}`,
    "--cs-chat-container-message-input-bg-color": white,
    "--cs-message-input-content-editor-wrapper-bg-color": defaultPrimary,
    "--di-message-input-content-editor-wrapper-border": "0",
    "--cs-message-input-content-editor-color": defaultColorText,
    "--cs-message-input-placeholder-color": defaultColorTextDisabled,
    "--cs-main-container-border": "0",
    "--cs-conversation-header-border-bottom": `1px solid ${defaultBorderColor}`,
    "--di-a11y-btn-color": defaultPrimaryDark,
    "--di-a11y-btn-outline": `2px solid ${defaultPrimaryDark}`,
    "--cs-main-container-bg-color": white,
    "--cs-conversation-info-color": defaultColorText,
    "--cs-conversation-bg-color": white,
    "--cs-conversation-name-color": defaultColorText,
    "--cs-sidebar-left-bg-color": white,
    "--cs-conversation-info-activated-color": defaultActivatedColor,
    "--cs-conversation-name-activated-font-weight": "normal",
    "--cs-conversation-border-bottom": "0",
    "--cs-conversation-activated-border-bottom": "0",
    "--cs-conversation-active-border-bottom": "0",
    "--cs-conversation-hover-bg-color": defaultHoverColor,
    "--cs-main-container-conversation-list-border-right": `1px solid ${defaultBorderColor}`,
    "--cs-message-separator-color": defaultPrimaryDark,
    "--cs-message-separator-bg-color": white,
    "--di-wc-header-btn-color": defaultPrimaryDark,
    "--di-wc-header-btn-border-color": defaultPrimaryDark,
    "--cs-typing-indicator-dot-bg-color": defaultPrimaryDark,
    "--help-text-color": defaultColorText,
    "--help-h1-color": defaultColorText,
    "--help-h2-color": defaultColorText,
    "--help-link-color": defaultPrimaryDark,
    "--cs-tooltip-color": white,
    "--cs-tooltip-border-color": "transparent",
    "--cs-tooltip-arrow-color": black,
    "--di-dynamic-button-color": white,
    "--di-dynamic-button-bg-color": defaultPrimaryDark,
    "--di-border-color": "",
    "--di-alert-bg-color": "#f8d7da",
    "--di-alert-color": "#842029",
    "--di-alert-border-color": "#f5c2c7",
    "--di-btn-color": white,
    "--di-btn-bg": "",
    "--di-btn-border-color": "",
    "--di-btn-hover-bg": "",
    "--di-btn-hover-border-color": "",
    "--cs-conversation-unread-bg-color": "var(--cs-color-danger)",
    "--cs-conversation-unread-color": white,
    "--di-conversation-unread-border": "0"
  },

  "mono": {
    "--bs-body-bg": white,
    "--di-bs-card-bg": white,
    "--cs-color-text": defaultColorText,
    "--cs-color-bg-hover": lightGray,
    "--cs-color-primary": lightGray,
    "--cs-conversation-header-color": defaultColorText,
    "--cs-conversation-header-bg-color": lightGray,
    "--cs-message-group-message-content-incoming-color": defaultColorText,
    "--cs-message-group-message-content-incoming-bg-color": lightGray,
    "--cs-message-group-message-content-outgoing-bg-color": darkGray,
    "--cs-message-group-message-content-outgoing-color": white,
    "--cs-button-send-color": darkGray,
    "--cs-button-arrow-color": darkGray,
    "--cs-conversation-active-bg-color": darkGray,
    "--cs-conversation-activated-bg-color": darkGray,
    "--cs-conversation-name-activated-color": white,
    "--di-message-content-border": "0",
    "--di-message-content-incoming-border": "0",
    "--cs-message-list-bg-color": white,
    "--cs-chat-container-message-input-border-top": `solid 1px #d1dbe3`,
    "--cs-chat-container-message-input-bg-color": white,
    "--cs-message-input-content-editor-wrapper-bg-color": lightGray,
    "--di-message-input-content-editor-wrapper-border": "0",
    "--cs-message-input-content-editor-color": defaultColorText,
    "--cs-message-input-placeholder-color": defaultMessageInputPlaceholderColor,
    "--cs-main-container-border": "0",
    "--cs-conversation-header-border-bottom": `1px solid ${defaultBorderColor}`,
    "--di-a11y-btn-color": defaultColorText,
    "--di-a11y-btn-outline": `2px solid ${defaultColorText}`,
    "--cs-main-container-bg-color": white,
    "--cs-conversation-info-color": defaultColorText,
    "--cs-conversation-bg-color": white,
    "--cs-conversation-name-color": defaultColorText,
    "--cs-sidebar-left-bg-color": white,
    "--cs-conversation-info-activated-color": lightGray,
    "--cs-conversation-name-activated-font-weight": "bold",
    "--cs-conversation-border-bottom": "0",
    "--cs-conversation-activated-border-bottom": "0",
    "--cs-conversation-active-border-bottom": "0",
    "--cs-conversation-hover-bg-color": lightGray,
    "--cs-main-container-conversation-list-border-right": `1px solid ${defaultBorderColor}`,
    "--cs-message-separator-color": defaultColorText,
    "--cs-message-separator-bg-color": white,
    "--di-wc-header-btn-color": defaultColorText,
    "--di-wc-header-btn-border-color": defaultBorderColor,
    "--cs-typing-indicator-dot-bg-color": defaultColorText,
    "--help-text-color": defaultColorText,
    "--help-h1-color": defaultColorText,
    "--help-h2-color": defaultColorText,
    "--help-link-color": black,
    "--cs-tooltip-color": white,
    "--cs-tooltip-border-color": "transparent",
    "--cs-tooltip-arrow-color": black,
    "--di-dynamic-button-color": white,
    "--di-dynamic-button-bg-color": defaultColorText,
    "--di-border-color": "",
    "--di-alert-bg-color": lightGray,
    "--di-alert-color": defaultColorText,
    "--di-alert-border-color": defaultBorderColor,
    "--di-btn-color": black,
    "--di-btn-bg": lightGray,
    "--di-btn-border-color": lightGray,
    "--di-btn-hover-bg": darkGray,
    "--di-btn-hover-border-color": darkGray,
    "--cs-conversation-unread-bg-color": lightGray,
    "--cs-conversation-unread-color": black,
    "--di-conversation-unread-border": "0"
  },

  "hicontrast": {
    "--bs-body-bg": black,
    "--di-bs-card-bg": black,
    "--cs-color-text": yellow,
    "--cs-color-bg-hover": lightGray,
    "--cs-color-primary": lightGray,
    "--cs-conversation-header-color": yellow,
    "--cs-conversation-header-bg-color": black,
    "--cs-message-group-message-content-incoming-color" : yellow,
    "--cs-message-group-message-content-incoming-bg-color": black,
    "--cs-message-group-message-content-outgoing-bg-color": black,
    "--cs-message-group-message-content-outgoing-color": yellow,
    "--cs-button-send-color": yellow,
    "--cs-button-arrow-color": yellow,
    "--cs-conversation-active-bg-color": black,
    "--cs-conversation-activated-bg-color": black,
    "--cs-conversation-name-activated-color": blue,
    "--di-message-content-border": `2px solid ${yellow}`,
    "--di-message-content-incoming-border": `2px solid ${blue}`,
    "--cs-message-list-bg-color": black,
    "--cs-chat-container-message-input-border-top": `2px solid ${blue}`,
    "--cs-chat-container-message-input-bg-color": black,
    "--cs-message-input-content-editor-wrapper-bg-color": black,
    "--di-message-input-content-editor-wrapper-border": `2px solid ${yellow}`,
    "--cs-message-input-content-editor-color": yellow,
    "--cs-message-input-placeholder-color": yellow,
    "--cs-main-container-border": "0",
    "--cs-conversation-header-border-bottom": `2px solid ${blue}`,
    "--di-a11y-btn-color": yellow,
    "--di-a11y-btn-outline": `2px solid ${yellow}`,
    "--cs-main-container-bg-color": black,
    "--cs-conversation-info-color": yellow,
    "--cs-conversation-bg-color": black,
    "--cs-conversation-name-color": yellow,
    "--cs-sidebar-left-bg-color": black,
    "--cs-conversation-info-activated-color": blue,
    "--cs-conversation-name-activated-font-weight": "bold",
    "--cs-conversation-border-bottom": `2px solid ${yellow}`,
    "--cs-conversation-activated-border-bottom": `2px solid ${yellow}`,
    "--cs-conversation-active-border-bottom": `2px solid ${yellow}`,
    "--cs-conversation-hover-bg-color": black,
    "--cs-main-container-conversation-list-border-right": `2px solid ${yellow}`,
    "--cs-message-separator-color": yellow,
    "--cs-message-separator-bg-color": black,
    "--di-wc-header-btn-color": yellow,
    "--di-wc-header-btn-border-color": yellow,
    "--cs-typing-indicator-dot-bg-color": yellow,
    "--help-text-color": yellow,
    "--help-h1-color": yellow,
    "--help-h2-color": blue,
    "--help-link-color": blue,
    "--cs-tooltip-color": yellow,
    "--cs-tooltip-border-color": yellow,
    "--cs-tooltip-arrow-color": yellow,
    "--di-dynamic-button-color": yellow,
    "--di-dynamic-button-bg-color": black,
    "--di-border-color": yellow,
    "--di-alert-bg-color": black,
    "--di-alert-color": yellow,
    "--di-alert-border-color": yellow,
    "--di-btn-color": yellow,
    "--di-btn-bg": black,
    "--di-btn-border-color": yellow,
    "--di-btn-hover-bg": black,
    "--di-btn-hover-border-color": blue,
    "--cs-conversation-unread-bg-color": black,
    "--cs-conversation-unread-color": yellow,
    "--di-conversation-unread-border": `1px solid ${yellow}`
  }

}