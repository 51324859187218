import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import {SendButton} from "@chatscope/chat-ui-kit-react";
import "./help.scss";

export const HelpPage = () => {
  
  return (
    <div className="container help h-100 overflow-auto">
      
      <Helmet>
        <title>Czat konsultant - Pomoc</title>
      </Helmet>
      
      <nav className="mt-4">
        <Link to="/" className="help__link">Powrót to okna czat</Link>
      </nav>
      <main className="mt-4">
        <h1 className="help__h1">Czat konsultant - pomoc</h1>
        <section>
        <h2 className="help__h2">Informacje ogólne</h2>
        <p>
          Czat konsultant to aplikacja służąca do obsługi rozmów online z klientami.
        </p>
        <p>
          Aplikacja składa się z dwóch okien.<br/><br />
          <dl>
            <dt>Lista czatów (klientów)</dt>
            <dd>Okno, w którym znajduje się lista aktualnie obsługiwanych czatów.</dd>
            <dt>Okno rozmowy</dt>
            <dd>Okno, w którym prowadzona jest rozmowa z aktualnie wybranym klientem.</dd>
          </dl>
        </p>
          <section>
            <h2>Wybór klienta</h2>
            <p>
              Lista przychodzących rozmów czat, znajduje się w kolumnie po lewej stronie.
            </p>
            <p>Aby zmienić rozmówcę kliknij lewym przyciskiem wybrany element na liście.</p>
            <p>Jeżeli korzystasz z klawiatury możesz wybrać rozmówcę używając klawisza <strong>Enter</strong> lub <strong>Spacja</strong>.</p>
          </section>
        </section>
        <section>
          <h2 className="help__h2">Redagowanie i wysyłanie wiadomości</h2>
          <p>Wiadomości możesz wpisywać w oknie tekstowym znajdującym się w dolnej części okna rozmowy.</p>
          <p>Okno tekstowe jest wielowierszowe, czyli pozwala na wpisanie kilku linii tekstu.</p>
          <p>Aby wprowadzić znak nowej linii użyj kombinacji klawiszy <strong>SHIFT</strong>+<strong>ENTER</strong>.</p>
          <p>Aby wysłać wiadomość użyj przycisku <SendButton aria-hidden="true"/><strong>Wyślij</strong> w aplikacji lub klawisza <strong>ENTER</strong> na klawiaturze.</p>
        </section>
        <section>
          <h2 className="help__h2">Nawigacja przy użyciu klawiatury</h2>
          <p>Poruszanie się pomiędzy elementami sterującym aplikacji:<br />
            klawisz <strong>TAB</strong> - przenosi do następnego elementu,<br />
            kombinacja klawiszy <strong>SHIFT</strong> + <strong>TAB</strong> - przenosi do poprzedniego elementu.</p>
          <p>Poruszanie się pomiędzy wiadomościami w oknie wiadomości:<br />
            klawisz <strong>Strzałka w dół</strong> przenosi do następnej wiadomości,<br />
            klawisz <strong>Strzałka w górę</strong> przenosi do poprzedniej wiadomości,<br />
            klawisz <strong>Home</strong> przenosi do pierwszej wiadomości,<br />
            klawisz <strong>End</strong> przenosi do ostatniej wiadomości.</p>
          <p>Poruszanie się pomiędzy rozmówcami na liście rozmów:<br />
            klawisz <strong>Strzałka w dół</strong> przenosi do następnego rozmówcy,<br />
            klawisz <strong>Strzałka w górę</strong> przenosi do poprzedniego rozmówcy.</p>
          <p>Wybór rozmówcy na liście rozmów: <br />
            klawisz <strong>Enter</strong> lub <strong>Spacja</strong>.</p>
          <p>Po wybraniu rozmówcy focus zostanie automatycznie ustawiony na polu wpisywania wiadomości.</p>
        </section>
      </main>
      <nav className="mb-4">
        <Link to="/" className="help__link">Powrót do okna czat</Link>
      </nav>
  </div>
  );
  
}