import {useCallback, useEffect, useRef, useState} from "react";
import {useChat, User, Presence, UserStatus} from "@chatscope/use-chat";
import type {DiChatAdapter} from "../../../di-chat-adapter";
import {useDispatch } from "react-redux";
import {setIsLoggedIn, setUserType} from "../../../redux/appSlice";

enum InitStage {
  None,
  ServiceInitialized,
  TokenNotReceived,
  LoggedIn,
  ConversationsReceived,
  Ready
}

const getServerUrl = () => process.env.REACT_APP_APP_SERVER_URL ?? ""; 
const getApiUrl = () => process.env.REACT_APP_APP_API_URL ?? "";

export const useInitializeChatEffect = () => {
  
  const receiving = useRef(false);
  const [initStage, setInitStage] = useState<InitStage>(InitStage.None);
  const [initialized, setInitialized] = useState(false);
  
  const dispatch = useDispatch();
  
  const { service , setCurrentUser, setActiveConversation, activeConversation} = useChat();
  
  const initializeService = useCallback(async () => {
    
      dispatch(setUserType("user"));
      
      const serverUrl = getServerUrl();
      const apiUrl = getApiUrl();
      
      const chatService = (service as unknown as DiChatAdapter);
      await chatService.init({
        server: serverUrl,
        api: apiUrl,
        autoAccept: true // Agent akceptuje automatycznie rozmowy
      });
      console.debug("[initializeService] Set state to ServiceInitialized");
      setInitStage(InitStage.ServiceInitialized);

  },[service, dispatch]);

  const register = useCallback(async () => {
    
      const chatService = (service as unknown as DiChatAdapter);
      const registerResponse = await chatService.register({
        id: "",
        username: "",
        avatar: "",
        status: UserStatus.Available,
      });

      console.debug("[initializeService] Register response", registerResponse);

      setCurrentUser(new User({

        id: registerResponse.result.id,
        username: registerResponse.result.username,
        avatar: registerResponse.result.avatar,
        email:"",
        firstName:"",
        lastName:"",
        bio:"",
        presence: new Presence({
          status: registerResponse.result.status,
          description: ""
        })
      }));
      
      setInitStage(InitStage.LoggedIn);
      
    },
    [service, setCurrentUser],
  );
  
  
  /**
   * - Pobiera token
   * - Łączy się
   * - Pobiera dane użytkownika
   * - Rejestruje
   * - Ustawia aktualnego użytkownika
   */
  const getToken = useCallback( async () => {

    const chatService = (service as unknown as DiChatAdapter);
    
    // Pobieram token,
    // jeżeli go mam, to mogę się zarejestrować
    const getTokenResponse = await chatService.getToken();
    
    if ( getTokenResponse.result ) {
            
      await chatService.connect();
      await register();
      
    } else {
      
      setInitStage(InitStage.TokenNotReceived);
    }
  },[service, register]);
  
  /**
   * - Pobiera kontakty
   * - Pobiera konwersacje
   * - Ustawia aktywną konwersację
   * 
   */
  const getConversations = useCallback(async () => {
    
      const chatService = (service as unknown as DiChatAdapter);
      await chatService.getContacts();

      const conversationsResponse = await chatService.getConversations();

      const cLen = conversationsResponse.items.length;
      // Dla usera ustawiam pierwszą jako aktywną, jeżeli jakaś jest
      if ( cLen > 0 ) {
        setActiveConversation(conversationsResponse.items[0].id);
      }
      
      setInitStage(InitStage.ConversationsReceived);
      
  }, [service,setActiveConversation]);
  
  const getMessages = useCallback(async () => {
    
    if ( activeConversation ) {
      
    const chatService = (service as unknown as DiChatAdapter);
    const getMessagesResponse = await chatService.getMessages({conversationId: activeConversation?.id});
    
      console.debug("[initializeService] GetMessages response ", getMessagesResponse);
      setInitStage(InitStage.Ready);
      
    }
    
  }, [activeConversation, service]);
  
  useEffect(() => {
    
    console.debug(`UseEffect, initStage: ${initStage}`);
    if ( initStage === InitStage.None ) {
      console.debug("[initializeService] InitStage: None");
      initializeService();   
    } else if ( initStage === InitStage.ServiceInitialized ) {
      console.debug("[initializeService] InitStage: ServiceInitialized");
      getToken();
    } else if (initStage === InitStage.TokenNotReceived ) {
      console.debug("[initializeService] InitStage: TokenNotReceived");
      setInitialized(true);
    } else if (initStage === InitStage.LoggedIn ) {
      console.debug("[initializeService] InitStage: LoggedIn");
      if ( receiving.current === false ) {
        receiving.current = true;
        getConversations().then(() => receiving.current = false);
      }
    } else if (initStage === InitStage.ConversationsReceived ) {
      console.debug("InitStage: ConversationsReceived");
      setInitStage(InitStage.Ready);
    } else if ( initStage === InitStage.Ready ) {
      console.debug("[initializeService] InitStage: Ready");
      setInitialized(true);
      dispatch(setIsLoggedIn(true))
    }
    
  },[initStage, initializeService, getToken, getConversations, getMessages, dispatch]);
  
  
  return initialized;
  
};