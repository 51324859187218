import {ReactNode} from "react";
import {HelmetProvider} from "react-helmet-async";
import {TooltipProvider} from "../Chat/TooltipProvider";

export interface WidgetProps {
  component: ReactNode;
}

export const Widget = ({component}: WidgetProps) => {
  return (
    
      <HelmetProvider>
        <TooltipProvider>
          {component}
        </TooltipProvider>
      </HelmetProvider>
    
  );
};