import {createSelector} from "@reduxjs/toolkit";
import type {RootState} from "../store";

const app = (state:RootState) => state.app;

export const userTypeSelector = createSelector([app], app => app.userType );

export const themeSelector = createSelector([app], app => app.theme);

export const fontSizeSelector = createSelector([app], app => app.fontSize);

export const isMaximizedSelector = createSelector([app], app => app.isMaximized);

export const isOpenedSelector = createSelector([app], app => app.isOpened);

export const isMobileSelector = createSelector([app], app => app.isMobile);

export const isLoggedInSelector = createSelector([app], app => app.isLoggedIn );