import {Message, MessageGroup, MessageList, MessageSeparator, TypingIndicator} from "@chatscope/chat-ui-kit-react";
import {MessageDirection, useChat} from "@chatscope/use-chat";
import {useMessageListKeyboardNavigation} from "./useMessageListKeyboardNavigation";
import {ReactNode, useCallback, useEffect, useMemo} from "react";
import type {FocusEvent} from "react";
import "./chat-message-list.scss";
import classNames from "classnames";
import {useTypingIndicator} from "../hooks/useTypingIndicator";
import Linkify from "react-linkify";
const debug = process.env.REACT_APP_DEBUG === "true";

interface ChatMessageListProps {
  as: string;
  noMessages?: ReactNode;
}
export const ChatMessageList = ({noMessages}:ChatMessageListProps) => {
  
  const {currentMessages, activeConversation} = useChat();
  
  const {onMessageKeyDown, onCellFocus, onCellBlur, lastFocusedMessage} = useMessageListKeyboardNavigation();
  
  const {typingIndicator, found: typingIndicatorEnabled} = useTypingIndicator(activeConversation?.id, (typingUser => 
    <span className={classNames("di-typing-indicator__content", {"d-none": !typingUser})}
          role="gridcell"
          data-focusable-content      
          data-typing-indicator-content
          onFocus={(evt) => onCellFocus(evt,"typing-indicator")}
          onBlur={onCellBlur}
          tabIndex={(() => {

            // Tabindex jest włączony, jeżeli jest to ostatnio zaznaczona wiadomość
            // lub ostatnio zaznaczona jest pusta i jest piszący użytkownik
            const lastFocused = lastFocusedMessage.current;
            return "typing-indicator" === lastFocused
            || (lastFocused === null && typingUser) ? 0 : -1;

          })() }
    >{typingUser ?  `${typingUser.data.type === "user" ? "Konsultant" : "Klient"} pisze wiadomość...` : ''}</span>));
  
  useEffect(() => {
    document.querySelector<HTMLDivElement>("#messageList .ps__thumb-y")?.removeAttribute("tabindex");
    document.querySelector<HTMLDivElement>("#messageList .ps__rail-y")?.setAttribute("aria-hidden", "true");
  },[]);
  
  if ( debug ) {
    console.log(`LAST FOCUSED MESSAGE: ${lastFocusedMessage.current}`);
  }
  
  //const messageListAriaLabel = useMemo(() => name ? `Okno wiadomości, rozmowa z ${name}` : "Okno wiadomości, rozmowa ze sklepem internetowym",[name]);//const messageListAriaLabel = useMemo(() => name ? `Okno wiadomości, rozmowa z ${name}` : "Okno wiadomości, rozmowa ze sklepem internetowym",[name]);
  const messageListAriaLabel = useMemo(() => `Okno wiadomości`,[]);
  
  const onTypingIndicatorBlur = useCallback((evt:FocusEvent) => {
    // Łapiemy tutaj blur, tak naprawdę pochodzący z focusout
    // wygenerowany przez znikający content typing indicatora.
    // Znikający, czyli taki, któremu zmieniło się display na none.
    // Nie możemy usunąć całkiem elementu ponieważ wtedy react nie złapie blura:
    // https://github.com/facebook/react/issues/12363
    if ( evt.relatedTarget === null) {
      // Jeżeli relatedTarget jest pusty, to znaczy, że focus mógł przejść na body
      // lub użytkownik kliknął gdzieś poza stroną np. w pasek adresu.
      // Jednak nie ma to wtedy większego znaczenia, bo i tak focus będzie poza przeglądarką.
      // Szukam ostatniej wiadomości w ostatniej grupie i ustawiam na nią focus
      // Jeżeli nic nie znajdę to nie ma to znaczenia, bo typing indicator nie będzie widoczny nigdy przed rozpoczęciem rozmowy
      // czyli zawsze będą jakieś wiadomości
      const lastGroup = document.querySelector<HTMLDivElement>("[data-cs-message-group]:last-of-type");
      if ( lastGroup ) {
        // console.log("Jest ostatnia grupa. Ustawiam focus na ostatnią wiadomość");
        if (lastGroup.hasAttribute("data-system-message")) {
          lastGroup.querySelector<HTMLDivElement>("[data-separator]:last-child [data-focusable-content]")?.focus();
        } else {
          lastGroup.querySelector<HTMLDivElement>("[data-cs-message]:last-child [data-focusable-content]")?.focus();
        }
      }
    }
  },[]);
  
  
  return (
    <MessageList id="messageList" aria-live="off"
                 role="grid" aria-label={messageListAriaLabel}
                 typingIndicator={<TypingIndicator id="message-list-typing-indicator" 
                                                   aria-live="off"
                                                   role="row"
                                                   className={classNames("di-typing-indicator", {
                                                      "di-typing-indicator--hidden": !typingIndicatorEnabled
                                                    })}
                                                   onKeyDown={onMessageKeyDown}
                                                   onBlur={onTypingIndicatorBlur}                                                    
                                                   {...typingIndicator}
                                                           />}>
      {currentMessages.length === 0 && noMessages}
      {currentMessages.map( (g,gi) =>
        <MessageGroup key={g.id}
                      direction={g.direction} role="rowgroup"
                      className={classNames({"di-message-group__system": g.messages.length > 0 && g.messages[0].senderId === "SYSTEM"})}
                      data-messages={g.messages.length > 0 && g.messages[0].senderId !== "SYSTEM" ? true : null}
                      data-system-message={g.messages.length > 0 && g.messages[0].senderId === "SYSTEM" ? true : null}
        >
          <MessageGroup.Messages>
            {g.messages.map( (m, mi) =>
              m.senderId === "SYSTEM" 
                ? <MessageSeparator key={m.id} className="di-chat-message-separator" 
                                    role="row"
                                    data-separator
                                    onKeyDown={onMessageKeyDown}
                >
                  <div role="gridcell"
                       data-focusable-content 
                       onFocus={(evt) => onCellFocus(evt, m.id)}
                       onBlur={onCellBlur}
                       tabIndex={(() => {
                         if ( debug ) {
                           console.log(`[Separator tabindex id: ${m.id} ] GI: ${gi} currentMessagesLength: ${currentMessages.length - 1}`);
                           console.log(`[Separator tabindex id: ${m.id} ] lastFocusedMessage`, lastFocusedMessage.current);
                         }
                         
                         // Tabindex jest włączony, jeżeli jest to ostatnio zaznaczona wiadomość
                         // lub jest to ostatnia wiadomość i ostatnio zaznaczona jest pusta oraz nie ma widocznego typing indicatora
                         const lastFocused = lastFocusedMessage.current;
                         
                         return m.id === lastFocused
                         || (!typingIndicatorEnabled && lastFocused === null && gi === currentMessages.length - 1 && mi === g.messages.length - 1) ? 0 : -1;
                         
                       })()} 
                  ><>
                    <span className="visually-hidden">Informacja: </span>
                    {debug ? <>{m.content} | {m.id}</> : m.content}
                  </>
                  </div>
            </MessageSeparator> 
                :  <Message key={m.id}
                            model={{
                    //sender: m.direction === MessageDirection.Incoming ? "Konsultant" : "Ja",
                    type: "html",
                    direction: m.direction === MessageDirection.Incoming ? "incoming" : "outgoing",
                    position: "normal"
                  }}
                         role="row"
                               onKeyDown={onMessageKeyDown}
              >
                <Message.CustomContent>
                  <div role="gridcell"
                       /*aria-live="off"*/
                       tabIndex={(() => {
                         
                         if ( debug ) {
                           console.log(`[Message tabindex id: ${m.id}] GI: ${gi} currentMessagesLength: ${currentMessages.length - 1}`);
                           console.log(`[Message tabindex id: ${m.id}] lastFocusedMessage`, lastFocusedMessage.current);
                         }
                         
                         // Tabindex jest włączony, jeżeli jest to ostatnio zaznaczona wiadomość
                         // lub jest to ostatnia wiadomość i ostatnio zaznaczona jest pusta
                         const lastFocused = lastFocusedMessage.current;
                         
                         return m.id === lastFocused
                         || (!typingIndicatorEnabled && lastFocused === null && gi === currentMessages.length - 1 && mi === g.messages.length - 1) ? 0 : -1;
                         
                       })() }
                       data-focusable-content
                       onFocus={(evt) => onCellFocus(evt, m.id)}
                       onBlur={onCellBlur}
                  >
                    <>
                      <span className="visually-hidden">{m.direction === MessageDirection.Incoming ? "Klient: " : "Ty: "}</span>
                      <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                          <a target="_blank" rel="noreferrer" href={decoratedHref} key={key}>
                            {decoratedText}
                          </a>
                        )}
                      ><>{debug ? <>{m.content} | {m.id}</> : m.content}</></Linkify>
                    </>
                  </div>
                </Message.CustomContent>
              </Message>
            )}
          </MessageGroup.Messages>
        </MessageGroup>)}
    </MessageList>
  );
  
}
