import {
  ChatComponentPropsChildren,
  Conversation as ConversationElement,
  ConversationProps
} from "@chatscope/chat-ui-kit-react";
import {useGetParticipant} from "../hooks/useGetParticipant";
import {useChat} from "@chatscope/use-chat";
import type {Conversation} from "@chatscope/use-chat";

const stateDict:Record<string, string> = {
  3: "Aktywna",
  100: "Zakończona"
}

interface ConversationItemProps extends ChatComponentPropsChildren<ConversationProps,"div"> {
  conversation: Conversation;
}
export const ConversationItem = ({conversation, ...props}:ConversationItemProps) => {

  const {activeConversation} = useChat();
  const {name} = useGetParticipant(conversation);
  
  return <ConversationElement
    style={{margin: "2px", zIndex: 10}}
    active={activeConversation?.id === conversation.id}
    unreadCnt={conversation.unreadCounter}
    {...props}
  >
    <ConversationElement.Content name={name} info={stateDict[conversation.data.state]} />
  </ConversationElement>
}
