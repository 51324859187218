import React from 'react';
import {ChatPage} from "./components/Chat/ChatPage";
import {HelpPage} from "./components/Help/HelpPage";
import './themes/default/main.scss';
import {useSetTheme} from "./hooks/useSetTheme";
import {useInitializeChatEffect} from "./components/Chat/hooks/useInitializeChatEffect";
import {useGetMediaQueryFromLoaderEffect} from "./hooks/useGetMediaQueryFromLoader";
import { Routes, Route } from "react-router-dom";
import {useAppSelector} from "./redux/hooks";
import {isLoggedInSelector} from "./redux/selectors";
import {LoginPage} from "./components/LoginPage/LoginPage";
import {Widget} from "./components/Widget/Widget";

const App = () => {
  
  const initialized = useInitializeChatEffect();

  useGetMediaQueryFromLoaderEffect({enabled: initialized});
  
  useSetTheme({enabled: initialized});
  
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  
  if ( initialized ) {
    return (
      <Routes>
        {isLoggedIn === false && <Route path="/" element={<LoginPage />}  />}
        <Route path="/" element={<Widget component={<ChatPage />} />} />
        <Route path="/pomoc" element={<Widget component={<HelpPage />} />} />
      </Routes>
    );
  } else {
    return null;
  }
}

export default App;
