import {ChangeEvent, FormEvent, useCallback, useState} from "react";
import {Presence, useChat, User, UserStatus} from "@chatscope/use-chat";
import {DiChatAdapter} from "../../di-chat-adapter";
import {useAppDispatch} from "../../redux/hooks";
import {setIsLoggedIn} from "../../redux/appSlice";
import "./login-page.scss";
import {flushSync} from "react-dom";
import {A11YToolbox} from "../Chat/A11YToolbox";
import {TooltipProvider} from "../Chat/TooltipProvider";

export interface LoginData {
  username: string;
  password: string;
}


export const LoginPage = () => {
  
  const dispatch = useAppDispatch();
  
  const [data, setData ] = useState<LoginData>({username:"", password:""});
  
  const [error, setError] = useState(false);
  
  const [loading, setLoading] = useState(false);
  
  const {service, setCurrentUser} = useChat();
  
  const onInput = (evt:ChangeEvent<HTMLInputElement>) => {

    const newData = {...data, [evt.target.name]: evt.target.value};
    setData(newData);
    
  };

  const register = useCallback(async () => {
    
      const chatService = (service as unknown as DiChatAdapter);
      const registerResponse = await chatService.register({
        id: "",
        username: data.username,
        avatar: "",
        status: UserStatus.Available,
      });

      console.debug("[initializeService] Register response", registerResponse);

      setCurrentUser(new User({

        id: registerResponse.result.id,
        username: registerResponse.result.username,
        avatar: registerResponse.result.avatar,
        email:"",
        firstName:"",
        lastName:"",
        bio:"",
        presence: new Presence({
          status: registerResponse.result.status,
          description: ""
        })
      }));

      dispatch(setIsLoggedIn(true));
      

    },
    [service, setCurrentUser],
  );
  
  const onSubmit = useCallback(async (evt:FormEvent) => {
    evt.preventDefault();
    
    flushSync(() => {
      setError(false);
      setLoading(true);
    });
    
    const chatService = (service as unknown as DiChatAdapter);
    
    const loginResponse = await chatService.login({
      username: data.username,
      password: data.password,
    });

    console.debug("[initializeService] Login response", loginResponse);

    if ( loginResponse.result === false ) {
      setError(true);
      setLoading(false);
      return;
    }

    console.debug("[initializeService]  Logged in");

    await chatService.connect();

    console.debug("[initializeService] Websocket connected");

    await register();
    
  },[data, service]);
  
  return (
    <div className="d-flex flex-grow-1 flex-column justify-content-start align-items-center h-100 container">
      <div className="row mt-3 d-flex w-100">
        <div className="col d-flex justify-content-end">
          <TooltipProvider>
            <A11YToolbox help={false}/>
          </TooltipProvider>
        </div>
      </div>
      <div className="row di-chat-login mt-5">
        <div className="col">
          <div className="card border-0">
            <div className="card-body">
              <form onSubmit={onSubmit}>
                <h1 className="mb-3">Konsultant czat - logowanie</h1>
                <div className="mb-3">
                  <label htmlFor="username" className="form-label">Nazwa użytkownika</label>
                  <input name="username" type="text" className="form-control" value={data.username} onInput={onInput} disabled={loading}/>
                </div>
                <div className="mb-3">  
                  <label htmlFor="password" className="form-label">Hasło</label>
                  <input name="password" type="password" className="form-control" value={data.password} onInput={onInput} disabled={loading}/>
                </div>
                <button className="btn btn-primary w-100" type="submit">Zaloguj</button>
                
                <div aria-live="assertive" className="mt-4">
                  {error && <div className="alert alert-danger text-center">Błędne dane logowania</div>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}