import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleHalfStroke, faMinusCircle, faMoon, faPlusCircle, faSun, faQuestionCircle} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {themeSelector} from "../../redux/selectors";
import {useCallback} from "react";
import type {HTMLAttributes} from "react";
import {Theme} from "di-chat-model";
import {setTheme} from "../../redux/appSlice";
import {useSetFontSize} from "../../hooks/useSetFontSize";
import "./a11y-toolbox.scss";
import {Link} from "react-router-dom";
import {useTooltipContext} from "./TooltipProvider";

interface A11YToolboxProps extends HTMLAttributes<HTMLDivElement> {
  help?: boolean;
}

export const A11YToolbox = ({className, help = true, ...props}:A11YToolboxProps) => {
  
  const {useTooltips, hideTooltips} = useTooltipContext();
  useTooltips('[data-a11y-toolbox] [data-bs-toggle="tooltip"]');
  
  const dispatch = useDispatch();
  
  const currentTheme = useSelector(themeSelector);

  const onSetTheme = useCallback(
    (theme: Theme) => {
      dispatch(setTheme(theme))
    },
    [dispatch],
  );
  
  const {fontDefault, fontUp, fontDown, fontSizeFloat} = useSetFontSize("1rem", 0.25);

  return (
    <menu className={classNames("d-flex align-items-center m-0 p-0 a11y-toolbox", className)} data-a11y-toolbox {...props}>
        <li>
          <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "default"})}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Domyślny motyw graficzny"
                  data-bs-custom-class="di-tooltip"
                  onClick={() => {
                    hideTooltips();
                    onSetTheme("default");
                  }}>
            <FontAwesomeIcon icon={faSun} aria-hidden={true}/>
            <span className="visually-hidden">Włącz domyślny motyw graficzny</span>
          </button>
        </li>
        <li>
          <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "mono"})}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Czarno-biały motyw graficzny"
                  data-bs-custom-class="di-tooltip"
                  onClick={() => {
                    hideTooltips();
                    onSetTheme("mono");
                  }}>
            <FontAwesomeIcon icon={faMoon} aria-hidden={true}/>
            <span className="visually-hidden">Włącz czarno-biały motyw graficzny</span>
          </button>
        </li>
        <li>
          <button className={classNames("btn a11y-btn", {"a11y-btn--active": currentTheme === "hicontrast"})}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  data-bs-title="Kontrastowy motyw graficzny"
                  data-bs-custom-class="di-tooltip"
                  onClick={() => {
                    hideTooltips();
                    onSetTheme("hicontrast");
                  }}>
            <FontAwesomeIcon icon={faCircleHalfStroke} aria-hidden={true}/>
            <span className="visually-hidden">Włącz kontrastowy motyw graficzny</span>
          </button>
        </li>
      <li className="a11y-toolbox__separator" aria-hidden={true}>|</li>
      <li>
        <button className={"btn a11y-btn"}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Zwiększ rozmiar tekstu"
                data-bs-custom-class="di-tooltip"
                disabled={fontSizeFloat >= 1.75}
                onClick={() => {
                  fontUp();
                  hideTooltips();
                }}>
          <FontAwesomeIcon icon={faPlusCircle} aria-hidden={true}/>
          <span className="visually-hidden">Zwiększ rozmiar tekstu</span>
        </button>
      </li>
      <li>
        <button className={"btn a11y-btn"}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Ustaw domyślny rozmiar tekstu"
                data-bs-custom-class="di-tooltip"
                onClick={() => {
                  hideTooltips();
                  fontDefault();
                }}>
          <span className="a11y-toolbox__font-reset" aria-hidden={true}>A</span>
          <span className="visually-hidden">Ustaw domyślny rozmiar tekstu</span>
        </button>
      </li>
        <li className="a11y-toolbox__btn-wrapper"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-title="Zmniejsz rozmiar tekstu"
              data-bs-custom-class="di-tooltip">
          <button className={"btn a11y-btn"}
                  disabled={fontSizeFloat <= 0.75}
                  onClick={() => {
                    hideTooltips();
                    fontDown();
                  }}
          >
            <FontAwesomeIcon icon={faMinusCircle} aria-hidden={true}/>
            <span className="visually-hidden">Zmniejsz rozmiar tekstu</span>
          </button>
        </li>

      {help && <>
      <li className="a11y-toolbox__separator d-flex" aria-hidden={true}>|</li>
      <li className="d-flex align-items-center a11y-toolbox__app-size">
        <Link className={"btn a11y-btn"}
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-title="Pomoc"
                to="/pomoc"
                data-bs-custom-class="di-tooltip">
          <FontAwesomeIcon icon={faQuestionCircle} aria-hidden={true}/>
          <span className="visually-hidden">Pokaż pomoc</span>
        </Link>
      </li>
      </>}
      
    </menu>);

}